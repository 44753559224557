// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---gatsby-ghost-theme-src-templates-post-js": () => import("./../../gatsby-ghost-theme/src/templates/post.js" /* webpackChunkName: "component---gatsby-ghost-theme-src-templates-post-js" */),
  "component---gatsby-ghost-theme-src-templates-archive-js": () => import("./../../gatsby-ghost-theme/src/templates/archive.js" /* webpackChunkName: "component---gatsby-ghost-theme-src-templates-archive-js" */),
  "component---gatsby-ghost-theme-src-templates-page-js": () => import("./../../gatsby-ghost-theme/src/templates/page.js" /* webpackChunkName: "component---gatsby-ghost-theme-src-templates-page-js" */),
  "component---gatsby-ghost-theme-src-templates-home-js": () => import("./../../gatsby-ghost-theme/src/templates/home.js" /* webpackChunkName: "component---gatsby-ghost-theme-src-templates-home-js" */),
  "component---gatsby-ghost-theme-src-auto-pages-author-js": () => import("./../../gatsby-ghost-theme/src/auto-pages/author.js" /* webpackChunkName: "component---gatsby-ghost-theme-src-auto-pages-author-js" */),
  "component---gatsby-ghost-theme-src-auto-pages-tag-js": () => import("./../../gatsby-ghost-theme/src/auto-pages/tag.js" /* webpackChunkName: "component---gatsby-ghost-theme-src-auto-pages-tag-js" */),
  "component---gatsby-ghost-theme-src-auto-pages-404-js": () => import("./../../gatsby-ghost-theme/src/auto-pages/404.js" /* webpackChunkName: "component---gatsby-ghost-theme-src-auto-pages-404-js" */)
}

