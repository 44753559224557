module.exports = [{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":840,"quality":80},
    },{
      plugin: require('../../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/Users/janek/Projects/github_contrib/grzegorowski.com/gatsby-ghost-theme/src/utils/typography"},
    },{
      plugin: require('../../gatsby-ghost-theme/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"/Users/janek/Projects/github_contrib/grzegorowski.com/blog/content"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K4KJJKX","includeInDevelopment":false},
    }]
